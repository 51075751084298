<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">

          <a href="https://www.swapify.tn"><img width="60px" height="60px" src=".../../../assets/img/Logo-Swapify-3.png"> </a>
          <br>
          <br>
          <ion-list-header>Bienvenue</ion-list-header>
          <ion-note>{{current_user}}</ion-note>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-menu-toggle auto-hide="false" *ngFor="let label of labels">
          <ion-item  lines="none" [routerLink]="[label.url]" (click)="logout(label.url)">
            <ion-icon slot="start" [ios]="label.icon + '-outline'" [md]="label.icon + '-sharp'"></ion-icon>
            <ion-label>{{ label.title }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>


  </ion-split-pane>
</ion-app>
