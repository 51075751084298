import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  current_user!: string | null;

  constructor( public alertController : AlertController) {

    window.addEventListener('offline' , ()=> {
      this.openAlert() ;
    })
  }
  async openAlert(){
      const alert = await this.alertController.create({
        header : 'Problème réseau',
        message : 'Vérifier votre connexion internet' ,
        buttons : [{
          text : "ok",
          handler : ()=> {
            (navigator as any).app.exitApp();
          }
        }]
      })
      await alert.present() ;
  }



  ngOnInit(): void {
    this.current_user = localStorage.getItem('name')
  }

  public appPages = [
    { title: 'Swapify', url: '/myoffers', icon: 'home' },
    { title: 'Nouvelle demande', url: '/add-offer', icon: 'desktop' },
    { title: 'Mes offres', url: '/myoffers', icon: 'folder-open' },
    { title: 'Bonus swapify', url: '/myrequests', icon: 'gift' },

  ];

  public labels = [
    //{ title: 'Me connecter', url: '/login', icon: 'person' },
    { title: 'Paramétres', url: '/dashboard', icon: 'settings' },
    { title: 'Déconnexion', url: '/login', icon: 'exit' },
    //{ title: 'Mon compte', url: '/dashboard', icon: 'paper-plane' },
  ];

  logout(ev : any){
    if(ev == '/login')
    localStorage.clear()
  }

}
